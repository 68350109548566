import { Box, Grid, Theme, ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ErrorPage from './pages/ErrorPage';

export default function App() {
  const theme = constructTheme();

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

function constructTheme(): Theme {
  const theme = createTheme({
    palette: {
      primary: { main: '#6c7eaa' },
      secondary: { main: '#b62324' },
      mode: 'light',
      background: { default: '#fff' },
      text: {
        primary: '#222',
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
    },
  });

  return responsiveFontSizes(theme);
}
