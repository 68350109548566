import { AppBar, Box, Button, Divider, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from '../assets/kphealth-logo.webp';
import { CSSProperties } from "react";

interface HeaderProps {
  height: number;
  isMobile: boolean;
}

export default function Header({ height, isMobile }: HeaderProps) {
  const headerItemStyles: CSSProperties = { flexGrow: 1, textTransform: 'uppercase' };
  const logoStyles: CSSProperties = { width: isMobile ? 32 : 64, padding: 0, margin: 0 }

  return (
    <Box sx={{ minHeight: height }}>
      <AppBar position="fixed">
        <Toolbar>
          <Button
            size="large"
            color="inherit"
            aria-label="menu"
            component={Link}
            to="/"
          >
            <img src={Logo} alt="KP Health" style={logoStyles} />
          </Button>
          <Box>
            <Typography variant="h4">
              <Link to="/" style={{ color: 'white', textDecoration: 'none', fontWeight: 'bold' }}>
                KP Health
              </Link>
            </Typography>
            {!isMobile && (
              <Typography variant="body1" sx={headerItemStyles}>
                Private duty nursing services
              </Typography>
            )}
          </Box>

          {!isMobile && (
            <Link
              to="#about"
              style={{ marginLeft: 'auto', color: 'white', textDecoration: 'none' }}
              onClick={() => document.getElementById('about')?.scrollIntoView()}>
              <Typography variant="h6" component="div" sx={headerItemStyles}>
                {isMobile ? 'About' : 'About Us'}
              </Typography>
            </Link>
          )}

          <Link
            to="#contact"
            style={{ marginLeft: isMobile ? 'auto' : 20, color: 'white', textDecoration: 'none' }}
            onClick={() => document.getElementById('contact')?.scrollIntoView()}>
            <Typography variant="h6" component="div" sx={headerItemStyles}>
              Contact {isMobile ? 'Us' : ''}
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  );
}