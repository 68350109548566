import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <Grid justifyContent={'center'}>
      <Grid item xs={12}>
        <Typography variant="h3" align="center" gutterBottom>Error</Typography>
        <Typography variant="body1">
          Sorry, an error occurred. <Link to="/">Return to the home page</Link>.
        </Typography>
      </Grid>
    </Grid>
  );
}