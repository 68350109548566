import { Box, Container, Divider, Grid, List, ListItem, ListItemText, Typography, useMediaQuery } from '@mui/material';
import Hero1 from '../assets/hero-1.webp';
import Hero2 from '../assets/hero-2.webp';
import Hero3 from '../assets/hero-3.webp';
import Header from '../components/Header';

export default function HomePage() {
  const smallDisplay = useMediaQuery('(max-width: 1000px)');

  const ourServices: string[] = [
    'Patients needing help with daily tasks and medication',
    'Patients recovering from surgery or medical procedures',
    'Patients with chronic illnesses like diabetes, heart disease, or COPD',
    'Individuals with disabilities',
    'People with terminal illnesses',
    'Individuals with traumatic injuries or neurological conditions',
    'Patients with mental health disorders',
    'People needing skilled nursing care for procedures like wound care or infusion therapy',
  ];

  const nursingServices: string[] = [
    'Managing medical equipment, such as IVs, feeding tubes, or oxygen tanks',
    'Performing catheterizations, tracheostomy care, and other specialized nursing procedures',
    'Responding to medical emergencies and providing immediate interventions to stabilize patients',
    'Assisting with mobility and positioning to prevent pressure ulcers and other complications',
    'Educating patients and their families about their health conditions',
    'Creating treatment plans addressing patients\' individual needs',
    'Coordinating care with other healthcare professionals, including doctors, therapists, and caregivers',
  ];

  const heroCards = [
    {
      title: "Our Mission",
      body: <Box>
        <Typography variant='body1' paragraph sx={{ lineHeight: '2' }}>
          Our mission is to provide compassionate and reliable home health and skilled nursing care to our clients. We strive to improve their quality of life and help them maintain independence in the comfort of their own homes.
        </Typography>
        <Typography variant='body1' sx={{ lineHeight: '2' }}>
          Whether you’re managing a chronic condition or recovering from an illness, injury, surgery or hospitalization, we can help. We can provide the skilled nursing care, education and tools you need to live a healthier and happier life.
        </Typography>
      </Box>,
      image: Hero1,
    },
    {
      title: "We offer a wide array of services including:",
      body:
        <List sx={{ listStyleType: 'disclosure-closed', padding: 0 }}>
          {ourServices.map((service, index) => (
            <ListItem key={`our-services-parent-${index}`} sx={{ display: 'list-item', marginLeft: 2 }}>
              <ListItemText sx={{ margin: 0 }} key={`our-services-${index}`} primary={service} />
            </ListItem>
          ))}
        </List>,
      image: Hero2,
    },
    {
      title: "Our nurses also specialize in:",
      body:
        <List sx={{ listStyleType: 'disclosure-closed', padding: 0 }}>
          {nursingServices.map((service, index) => (
            <ListItem key={`nursing-services-parent-${index}`} sx={{ display: 'list-item', marginLeft: 2 }}>
              <ListItemText sx={{ margin: 0 }} key={`nursing-services-${index}`} primary={service} />
            </ListItem>
          ))}
        </List>,
      image: Hero3,
    },
  ];

  const topLevelStyles = { w: '100%', p: 2 };

  return (
    <Container sx={topLevelStyles}>
      <Header height={72} isMobile={smallDisplay} />
      <Grid
        direction="column"
        sx={{ padding: 0 }}
      >
        <Grid item xs={12} p={1}>
          <Typography variant='h4' style={{ textTransform: 'uppercase', textAlign: 'center' }} gutterBottom>
            Improving Health Care {smallDisplay && <br />}at Home
          </Typography>

          <Typography variant='subtitle1' sx={{ textAlign: 'center' }}>
            KP Health is founded on the principle that health care gets better when clients get better
            care at home—the place they most want to be.
          </Typography>
        </Grid>

        <Divider id='test' sx={{ marginY: 2 }} variant='fullWidth' />

        <Grid item xs={12}>
          {heroCards.map((heroCard, index) => (
            <HeroCard
              key={index}
              index={index}
              title={heroCard.title}
              body={heroCard.body}
              image={heroCard.image}
              isMobile={smallDisplay}
            />
          ))}
        </Grid>

        <Grid item xs={12}>
          <BioCard />
        </Grid>

        <Grid item xs={12} marginTop={4}>
          <ContactInfo />
        </Grid>

        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Container>
  )
}

interface HeroCardProps {
  index: number;
  title: string;
  body: React.ReactNode;
  image: string;
  isMobile: boolean;
}

function HeroCard({ index, title, body, image, isMobile }: HeroCardProps) {
  const widthStyle = {
    width: isMobile ? '100%' : '50%',
  };

  const imageComponent = (
    <img src={image} alt={title} style={{ ...widthStyle, borderRadius: 12 }} />
  );

  const textComponent = (
    <Box sx={{ ...widthStyle, marginLeft: index % 2 == 0 && !isMobile ? 3 : 0, marginRight: 3 }}>
      <Typography variant='h4' gutterBottom>
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        {body}
      </Box>
    </Box>
  );

  if (isMobile) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          {imageComponent}
        </Box>
        <Box sx={{ p: 1 }}>
          {textComponent}
        </Box>
      </Box>
    );
  }

  const rowStyles = {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 6,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  };

  if (index % 2 === 0) {
    return (
      <Box sx={rowStyles}>
        {imageComponent}
        {textComponent}
      </Box>
    );
  }

  return (
    <Box sx={rowStyles}>
      {textComponent}
      {imageComponent}
    </Box>
  );
}

function BioCard() {
  const bioStyles = {
    margin: 'auto',
    fontStyle: 'italic',
    lineHeight: '2',
    padding: 2,
  };

  return (
    <div id="about">
      <Typography variant='body1' align='justify' gutterBottom sx={bioStyles}>
        KP Health is a private duty nursing company that is owned and operated by Kriste Petite, RN,
        MSN. Kriste has been a registered nurse for over 40 years. She has a Bachelor of Science
        degree in Nursing from San Jose State University and a Master of Science degree in Community
        Health Nursing from Texas Woman&apos;s University.
      </Typography>
    </div>
  )
}

function ContactInfo() {
  return (
    <Box>
      <Divider variant="middle" />
      <Box padding={4}>
        <div id="contact">
          <Typography variant='h3' align='center' gutterBottom>
            Call, text or email us today at:
          </Typography>
          <Typography variant='h4' align='center' gutterBottom>
            <a href="mailto:kriste@kphealth.us" style={{ textDecoration: 'none' }}>
              kriste@kphealth.us
            </a>
          </Typography>
          <Typography variant='h4' align='center' gutterBottom>
            <a href="tel:+1707-933-6777" style={{ textDecoration: 'none' }}>
              (707) 933-6777
            </a>
          </Typography>
        </div>
      </Box>
      <Divider variant="middle" />
    </Box>
  );
}

/**
 * Inlined footer component
 * @returns a footer component
 */
function Footer() {
  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant='h6' align='center' gutterBottom>
        KP Health
      </Typography>
      <Typography variant='body1' align='center' gutterBottom>
        Private Duty Nursing Services
      </Typography>
      <Typography variant='body1' align='center' gutterBottom>
        &copy; 2024 KP Health. All rights reserved.
      </Typography>
    </Box>
  )
}